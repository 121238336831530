import axios from 'axios'
import { API_URL } from 'config'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getItem } from 'utils/localStorageController'

function Pay(props) {
  const [id, setId] = useState('')
  const [paymentId, setPaymentId] = useState('')
  const [orderId, setOrderid] = useState('')
  const [signature, setSignature] = useState('')
  const user = useSelector((state) => state.user)
  const userid = user.signIn.userid
  const a_token = getItem('a_token')
  console.log(orderId, 'oooooooooo')
  console.log(paymentId, orderId, signature, 'hi')
  const handleSubmit = async (e) => {
    e.preventDefault()

    var options = {
      key: 'rzp_test_zoTTOyx6qR6vnM',
      key_secret: 'gIQopRl8xSLiCELhVPBAtq33',
      amount: 250000,
      currency: 'INR',
      name: 'amazingproduct',
      email: 'gvivek548@gmail.com',
      order_id: id,
      // razorpay_id: razorpay_id,
      prefill: {
        name: 'rajkumar',
        email: 'ajithkumar@mail.comorins.com',
        contact: '7548876967',
      },
      notes: {
        address: 'Razorpay Corporate office',
      },
      theme: {
        color: '#3399cc',
      },
      handler: async function (response) {
        alert(response.razorpay_payment_id)
        setPaymentId(response.razorpay_payment_id)

        alert(response.razorpay_order_id)
        setOrderid(response.razorpay_order_id)

        alert(response.razorpay_signature)
        setSignature(response.razorpay_signature)
        try {
          const data = {
            razorpay_payment_id: response.razorpay_payment_id,
            order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
            userid: userid,
          }

          const payment = await axios.post(
            API_URL + 'razorpay/verify_payment_signature.php',
            data,
            {
              headers: {
                Authorization: `Bearer ${a_token}`,
              },
            }
          )

          console.log(payment.data, 'ddddddddddd')
        } catch (error) {
          console.error('Error verifying payment signature:', error)
        }
      },
    }
    var rzp1 = new window.Razorpay(options)
    rzp1.on('payment.failed', function (response) {
      alert(response.error.code)
      alert(response.error.description)
      alert(response.error.source)
      alert(response.error.step)
      alert(response.error.reason)
      alert(response.error.metadata.order_id)
      alert(response.error.metadata.payment_id)
    })
    rzp1.open()
  }
  useEffect(() => {
    const response = axios.post(API_URL + 'razorpay/pay.php',{
    header: {
    Accept: "application/json",
    "Content-type": "multiple/form-data",
    },
    })
    .then((response) => ((setId(response.data.id))))
    .catch((error) => {
    console.error('Error:', error);
    });
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);
    }, [])
//   useEffect(() => {
//     const response = axios
// .post(API_URL + 'razorpay/pay.php', {
// header: {
// Accept: 'application/json',
// 'Content-type': 'multiple/form-data',
// Authorization: `Bearer ${a_token}`,
// },
//     })
//     .then((response) => ((setId(response.data.id))))
//     .catch((error) => {
//     console.error('Error:', error);
//     });
//     const script = document.createElement('script');
//     script.src = 'https://checkout.razorpay.com/v1/checkout.js';
//     script.async = true;
//     document.body.appendChild(script);
//     }, [])
  return (
    <div
      className="App d-flex justify-content-center align-items-center"
      style={{ minHeight: '100vh' }}
    >
      <div className="text-center">
        <h1 className="text-white">Razorpay Payment Process</h1>
        <button
          type="button"
          id="rzp-button1"
          className="btn btn-success text-dark"
          onClick={handleSubmit}
        >
          Pay 2500
        </button>
      </div>
    </div>
  )
}

export default Pay
