import React, { useState } from 'react';
import './Sidebar1.css';

import {
  FaHome,
  FaUserFriends,









  
  FaHistory,
  FaTags,
  FaTree,
  FaEye,
  FaBars,
  FaTimes,
  FaWallet,
  FaMoneyBill,
  FaUser
} from 'react-icons/fa';
import { NavLink } from 'react-router-dom';


const SideBar = ({ mobileOpen, setMobileOpen, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  console.log("mobile",mobileOpen);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: '/dashboard',
      name: 'Dashboard',
      icon: <FaHome />
    },

    // {
    //     path:'/joinUser',
    //     name:'JoinUser',
    //     icon: <FaUserFriends />
    // },
    {
      path: '/tree',
      name: 'Tree',
      icon: <FaTree />
    },
    {
      path: '/paymentReceivedHistory',
      name: 'PaymentReceived',
      icon: <FaHistory />
    },

    {
      path: '/withdrawal-status',
      name: 'Withdrawal',
      icon: <FaMoneyBill />
    },
    {
      path: '/profile',
      name: 'My Profile',
      icon: <FaUser />
    }
  ];

  return (
    <div className='containers'>
      <div
        style={{
          width: mobileOpen ? '250px' : (mobileOpen ? '250px' : '0px'),
          display: mobileOpen || mobileOpen ? 'block' : 'none'
        }}
        className='sidebar'
      >
        {/* <div className='top_section'>
          <h1 style={{ display: mobileOpen ? 'block' : 'none' }} className='logo'>
            Logo
          </h1>
          <div style={{ marginLeft: mobileOpen ? '50px' : 0 }} className='bars'>
            {isOpen ? <FaTimes onClick={toggle} /> : <FaBars onClick={toggle} />}
          </div>
        </div> */}
        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className='link'
            activeClassName='active'
          >
            <div className='icon'>{item.icon}</div>
            <div style={{ display: mobileOpen ? 'block' : 'none' }} className='link_text'>
              {item.name}
            </div>
          </NavLink>
        ))}
      </div>
      <main className='main'>{children}</main>
    </div>
  );
};

export default SideBar;
