


import axios from 'axios';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from 'reduxStore/slices/Dashboard/AdminDashboarsSlice';
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Media,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

// core components
import IndexHeader from 'components/Headers/IndexHeader';
import CardsHeader from 'components/Headers/CardsHeader';
import AuthHeader from 'components/Headers/AuthHeader';


function AdminDashboardRoute() {

  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const UserDashboardState = useSelector((state) => state.userDashboard)
  const { income } = UserDashboardState
  const userid = user.signIn.userid;



  
 

  return (
    <>
    <CardsHeader name="Default" parentName="AdminDashboard" />
   <AuthHeader/>
</>
   
  );
}

export default AdminDashboardRoute;
