import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { getItem } from 'utils/localStorageController';
import { ConnectedRouter } from 'connected-react-router';
import { refresh} from 'reduxStore/slices/user/UserSlice';

import DefaultLayout from './layouts/DefaultLayout';
import Login from './views/Login';

// Home Page
import { Container } from 'sdq-ui';
import CodeEditor from 'components/CodeEditor';
import Razorpay from 'layouts/Razorpay';
import Admin from 'components/Sidebar/Admin/Admin';


function App({ history }) {
  const user = useSelector((state) => state.user);
  const { signIn } = user;
  const a_token = getItem('a_token');
  const dispatch=useDispatch()
  useEffect(() => {
    if (signIn.status === 0 && a_token !== null) {
      dispatch(refresh());
    }
  }, [signIn.status, a_token, dispatch]);


  return (
    <ConnectedRouter history={history}>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            name="Login"
            render={() =>
              signIn.status === 1 && signIn.role === 'admin' ? (
                <Redirect to="/admin" />
              ) : signIn.status === 1 && signIn.role === 'user'&& signIn.userstatus==='unlock' ? (
                <Redirect to="/dashboard" />
              ) : 
              signIn.status===1 && signIn.role==='user' && signIn.userstatus==='lock'?(
                <Redirect to='/razorpay'/>
              )
              :
              (
                <Container isNoPadding={true}>
                  <Login signIn={signIn} />
                </Container>
              )
            }
          />

          <Route
            path="/dashboard"
            name="Dashboard"
            render={() =>
              signIn.status === 1 && signIn.role === 'user' && signIn.userstatus==='unlock'? (
                <DefaultLayout />
              ) : (
                <Redirect to="/" />
              )
            }
          />

            <Route
            path="/razorpay"
            name="Razorpay"
            render={() =>
              signIn.status === 1 && signIn.role === 'user' &&signIn.userstatus==='lock' ? (
                <Razorpay />
              ) : (
                <Redirect to="/" />
              )
            }
          />
          <Route
            path="/admin"
            name="Admin"
            render={() =>
              signIn.status === 1 && signIn.role === 'admin' ? (
                <Admin />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            path="/code-editor"
            name="CodeEditor"
            render={() => <CodeEditor />}
          />
          <Route
            path="login"
            name="Login"
            render={() => <Login />}
          />

          <Redirect to="/" />
        </Switch>
        {/* <Dashboard/> */}
      </BrowserRouter>
    </ConnectedRouter>
  );
}

export default App;






// import React, { useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { BrowserRouter, Redirect, Route, Switch, Link } from 'react-router-dom';
// import { getItem } from 'utils/localStorageController';
// import { ConnectedRouter } from 'connected-react-router';

// // Containers
// import DefaultLayout from './layouts/DefaultLayout';

// // Login Page
// import Login from './views/Login';
// import Activate from './views/Activate';

// // Home Page
// import { Container } from 'sdq-ui';
// import { updateUserSession, fetchUserData } from 'reduxStore/slices/user/UserSlice';
// import CodeEditor from 'components/CodeEditor';
// import Admin from 'components/Sidebar/Admin/Admin';
// import Razorpay from 'layouts/Razorpay';
// // import Admin from 'components/Sidebar/Elements.js/Admin';

// function App({ history }) {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user);
//   const { signIn } = user;
//   const a_token = getItem('a_token');

//   // useEffect(() => {
//   //   dispatch(fetchUserData());
//   // }, [dispatch]);

//   return (
//     <BrowserRouter>
//       <Switch>
//         <Route exact path="/" component={Razorpay} />

//         {/* <Route path="/pin-request" component={PinRequestRoute} />
//         <Route path="/view-pin" component={ViewPin} />
//         <Route path="/join-User" component={JoinUser} />
//         <Route path="/tree" component={Tree} />
//         <Route path="/payment-received-history" component={PaymentReceived} /> */}

//         {/* <Route path="/activate" component={Activate} /> */}
//         <Route path="/defaultlayout" component={DefaultLayout} />
//         <Route path="/code-editor" component={CodeEditor} />
//         <Route path='/admin' component={Admin}/>
//         <Redirect to="/" />
//       </Switch>
//     </BrowserRouter>
//   );
// }

// export default App;







