
import axios from 'axios';
import { DataService } from 'config.axios';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { unlockHandling } from 'reduxStore/slices/UserInitialPayment/UserInitialPaymentSlice';
import { fetchData } from 'reduxStore/slices/UserInitialPayment/UserInitialPaymentSlice';
import './Table.css'; // Import the CSS file
export default function UsersInitialPayment() {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const UserInitialPayment = useSelector((state) => state.UserInitialPayment)
  const { payment } = UserInitialPayment

  useEffect(() => {
    dispatch(fetchData());
  }, []);

  const [data, setData] = useState([]);
  const viewIncomeDetails = async () => {
    try {
      const response = await DataService.get(
        'admin/initial_payment_receive.php'
      );

      setData(response.data);
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };
  console.log(data, "datasss")

  const handleSend = async (id, amount) => {
    try {
      const sendData = await axios.post(
        'admin/unlockuser.php',
        { id, amount }
      );
      viewIncomeDetails()
      alert('Unlocked Successfully')

      setData((prevData) => prevData.filter((item) => item.id !== id));
    } catch (error) {
      console.error('Error sending pin:', error);
    }
  };

  
  return (
    <div className="container ">
    <h1 className="font-weight-bold text-center">User Initial Payment History</h1>
    <div className="table-responsive-lg">
      <table className="table table-bordered">
        <thead>
          <tr>
            <th scope="col">
              <h3>
                <strong>S.n</strong>
              </h3>
            </th>
            <th scope="col">
              <h3>
                <strong>Id</strong>
              </h3>
            </th>
            <th scope="col">
              <h3>
                <strong>UserId</strong>
              </h3>
            </th>
            <th scope="col">
              <h3>
                <strong>Password</strong>
              </h3>
            </th>
            <th scope="col">
              <h3>
                <strong>Sponser Id</strong>
              </h3>
            </th>
            <th scope="col">
              <h3>
                <strong>DateOf Joining</strong>
              </h3>
            </th>
            <th scope="col">
              <h3>
                <strong>Mobile</strong>
              </h3>
            </th>
            <th scope="col">
              <h3>
                <strong>Status</strong>
              </h3>
            </th>
            <th scope="col">
              <h3>
                <strong>Unlock</strong>
              </h3>
            </th>

          </tr>
        </thead>
        <tbody>
          {Array.isArray(payment) && payment.map((item, index) => (
            <tr key={index + 1}>
              <td>
                <h2>{index + 1}</h2>
              </td>
              <td>
                <h2>{item.id}</h2>
              </td>
              <td>
                <h2>{item.userid}</h2>
              </td>
              <td>
                <h2>{item.password}</h2>
              </td>
              <td>
                <h2>{item.sponserId}</h2>
              </td>
              <td>
                <h2>{item.dateOf_joining}</h2>
              </td>
              <td>
                <h2>{item.mobile}</h2>
              </td>
              <td>
                <h2>{item.payment}</h2>
              </td>
             
             <td>
                  {item.initial_payment === 'paid' ? (
                    <button
                      className="btn btn-success"
                      onClick={() => dispatch(unlockHandling(item.id, item.userid))}
                    >
                      Unlock
                    </button>
                  ) : (
                    <button className="btn btn-danger">Lock</button>
                  )}
                </td>

            </tr>
          ))}
        </tbody>
      </table>

    </div>
    </div>
  );

}
