
import styled from 'styled-components'


export const HeaderWrap = styled.div`

{
display: flex;
justify-content: space-between;
align-items: center;
padding: 10px 20px;
background: rgb(21,174,180);
background: linear-gradient(90deg, rgba(21,174,180,0.8701681356136204) 26%, rgba(10,170,194,1) 69%, rgba(0,212,255,1) 100%);

@media screen and (max-width: 768px) {
  flex-direction: column;
}}

`;


export const LeftSectionBox = styled.div`
  display:inline-flex
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  @media screen and (max-width: 768px) {
    justify-content: center;
  }
`;

export const RightSectionBox = styled.div`
  display: inline-flex;
  flex-direction:row;
  @media screen and (max-width: 1100px) {
    display:inline-flex;
    flex-direction:row;
    row-gap:100px;
}

  @media screen and (max-width: 768px) {
    // margin-left: 120px;
    flex-direction:row;
    row-gap:200px;
  }
`;


export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #dfe1e6;

  @media screen and (max-width: 768px) {
    font-size: 15px;
      margin-right:20px;
  }
`;

export const TitleWarp = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin-left: 25px;

  padding: 20px 25px;
  @media screen and (max-width: 768px) {
    padding: 10px 20px;

  }
`;



export const BurgerIconWarp = styled.div`
  cursor: pointer;
background-color:green;
  @media screen and (max-width: 768px) {
    
    margin:10px;
  }
`;

export const SaamaLogoWrap = styled.div`
  cursor: pointer;
  margin-left: 15px;

  @media screen and (max-width: 375px) {
    
    margin:20px;
  }
`;

export const IconBox = styled.div`
  margin: 0px 3px;
  cursor: pointer;

  @media screen and (max-width: 768px) {
  padding:1px;
  }
`;

export const ProfileFirstLetter = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  line-height: 33px;

`;

export const ProfileIconWrapper = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  justify-content: center;
  @media screen and (max-width: 375px) {
    margin-left:90px;
  }
  @media screen and (max-width: 414px) {
    margin-left:90px;
  }
  @media screen and (max-width: 412px) {
    margin-left:10px;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  right: 10px;
  top: 50px;
  z-index: 1000;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #525f7f;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.15);
  border-radius: 0.4375rem;
  box-shadow: 0 50px 100px rgb(50 50 93 / 10%), 0 15px 35px rgb(50 50 93 / 15%),
    0 5px 15px rgb(0 0 0 / 10%);

  @media screen and (max-width: 768px) {
    right: 5px;
    top: 40px;
    min-width: 8rem;
    font-size: 0.8rem;
  }
`;

export const DropdownItem = styled.div`
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;

  @media screen and (max-width: 768px) {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;

  }
  

`;




export const MenuText = styled.span`
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
`

export const StudySelector = styled.div`
  float: right;
  top: 2px;
  right: 20px;
  position: relative;
  border-right: 1px solid #2684FF;
`