import React from 'react'
import { Title, TitleWarp } from '../style'
import karkalogo from 'assets/img/karka-logo/white-logo.png'

const AppTitle = () => {
  return (
    <TitleWarp>
      <Title 
      style={{color:'navy'}}
      >
        {/* <img src={karkalogo} alt="logo" height="80" /> */}
        Amazing product
      </Title>
    </TitleWarp>
  )
}

export default AppTitle
