/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from 'reduxStore/slices/Dashboard/UserDashboardSlice';
import { getItem } from 'utils/localStorageController';
import React, { useEffect } from 'react';
// reactstrap components
import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from 'reactstrap'


function CardsHeader({ name, parentName }) {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user);
  const UserDashboardState = useSelector((state) => state.userDashboard)
  const { income } = UserDashboardState
  const userid = user.signIn.userid;
  const a_token = getItem('a_token');
  useEffect(() => {
    dispatch(fetchData(userid, a_token));
  }, []);


  const cardData = [
    {
      title: 'Today Earnings',
      value: income.total_income,
      iconClassName: 'icon icon-shape bg-gradient-red text-white rounded-circle shadow',
      description: 'today income',
      icon:'ni ni-active-40'
    },
    {
      title: 'Total Earnings',
      value: income.total_income,
      iconClassName: 'icon icon-shape bg-gradient-green text-white rounded-circle shadow',
      description: 'total income',
      icon:'ni ni-money-coins'

    },
    {
      title: ' Wallet Balance',
      value: income.current_income,
      iconClassName: 'icon icon-shape bg-gradient-orange text-white rounded-circle shadow',
      description: 'current income',
      icon:'ni ni-chart-pie-35'

    },
    
    {
      title: 'Left Point',
      value: income.leftpoint,
      iconClassName: 'icon icon-shape bg-gradient-primary text-white rounded-circle shadow',
      description: 'left point',
      icon:'fas fa-chevron-left'

    },
    {
      title: 'Right Point',
      value: income.rightpoint,
      iconClassName: 'icon icon-shape bg-gradient-primary text-white rounded-circle shadow',
      description: 'right point',
      icon:'fas fa-chevron-right'

    },
    {
      title: 'Package',
      value: income.package,
      iconClassName: 'icon icon-shape bg-gradient-gray text-white rounded-circle shadow',
      description: 'package',
      icon:'fa fa-folder',
    
    },
    // {
    //   title: 'Total Withdraw',
    //   value: income.withdraw,
    //   iconClassName: 'icon icon-shape bg-gradient-purple text-white rounded-circle shadow',
    //   description: 'Total Withdraw',
    //   icon:'fa fa-money',
    // },
  ];

  return (
    <>
      <div className="header bg-info pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <h6 className="h2 text-white d-inline-block mb-0">{name}</h6>{' '}
                <Breadcrumb
                  className="d-none d-md-inline-block ml-md-4"
                  listClassName="breadcrumb-links breadcrumb-dark"
                >
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <i className="fas fa-home" />
                    </a>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      {parentName}
                    </a>
                  </BreadcrumbItem>
                  
                </Breadcrumb>
              </Col>
            </Row>


            {/* <Row>
            {cardData.map((item, index) => (
              <Col md="6" xl="4" className="text-center">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-dark mb-0 "
                        >
                          {item.title}
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          {item.value}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className={item.iconClassName}>
                          <i className={item.icon} />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-success mr-2">
                        <i className="fa fa-arrow-up" />
                      </span>{' '}
                      <span className="text-nowrap">{item.description}</span>
                    </p>
                  </CardBody>
                </Card>
              </Col>))}
            
              
            </Row> */}
             <Row>
      {cardData.map((item, index) => (
        <Col md="6" xl="4" className="text-center" key={index}>
          <Card className="card-stats">
            <CardBody>
              <Row>
                <div className="col">
                  <CardTitle tag="h5" className="text-uppercase text-dark mb-0 ">
                    {item.title}
                  </CardTitle>
                  <span className="h2 font-weight-bold mb-0">{item.value}</span>
                </div>
                <Col className="col-auto">
                  <div className={item.iconClassName}>
                    <i className={item.icon} />
                  </div>
                </Col>
              </Row>
              <p className="mt-3 mb-0 text-sm">
                <span className="text-success mr-2">
                  <i className="fa fa-arrow-up" />
                </span>{' '}
                <span className="text-nowrap mr-7 text-dark">{item.description}</span>
              </p>
              

            </CardBody>
          </Card>
        </Col>
      ))}
    </Row>
  


          </div>
        </Container>
      </div>
    </>
  )
}

CardsHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
}

export default CardsHeader