
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { joinUsers, setJoinUser, setSponserId, underuseridname, treeside } from 'reduxStore/slices/user/JoinSlice';
import { useSelector } from 'react-redux';
import './Join.css';
import { useHistory } from 'react-router-dom'; // Import useHistory hook
import { func } from 'prop-types';
const Join = () => {

    const history = useHistory();
    const treedata = useSelector((state) => state.tree);
    const loginuser = useSelector((state) => state.user.signIn.userid)
    const { underuser, clickside } = treedata
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const userid = user.signIn.userid;
    const [register, setRegister] = useState({
        userid: loginuser,
        email: '',
        mobile: '',
        address: '',
        under_userid: underuser,
        side: clickside,
        bankaccountno: '',
        sponserId: loginuser,
        name: '',
        dateOf_birth: '',
        maritalStatus: '',
        gender: '',
        country: '',
        state: '',
        district: '',
        city: '',
        pincode: '',
        bankName: '',
        account_HolderName: '',
        branch: '',
        ifscCode: '',
        fathersName: '',
        nomineeName: '',
        relationShip: '',
        pancardNo: '',
        aadharcardNo: '',
    })

    const [validationErrors, setValidationErrors] = useState({
        email: '',
        mobile: '',
        address: '',
        bankaccountno: '',
        under_userid: underuser,
        side: clickside,
        sponserId: '',
        dateOf_joining: '',
        name: '',
        dateOf_birth: '',
        maritalStatus: '',
        gender: '',
        country: '',
        state: '',
        district: '',
        city: '',
        pincode: '',
        bankName: '',
        account_HolderName: '',
        branch: '',
        ifscCode: '',
        fathersName: '',
        nomineeName: '',
        relationShip: '',
        pancardNo: '',
        aadharcardNo: ''
    });

    useEffect(() => {
        dispatch(setSponserId(userid))
        dispatch(underuseridname(underuser))
        dispatch(treeside(clickside))
        // setRegister('')
    }, [])

    function validateForm() {
        const errors = {};


        // Validate mobile
        if (register.mobile.trim() === '') {
            errors.mobile = 'Mobile is required';
        } else if (!isValidMobile(register.mobile)) {
            errors.mobile = 'Invalid mobile number';
        }

        // Validate address
        if (register.address.trim() === '') {
            errors.address = 'Address is required';
        }


        // Validate email
        if (register.email.trim() === '') {
            // if (register.mobile && register.mobile.trim() === '') {

            errors.email = 'Email is required';
        } else if (!isValidEmail(register.email)) {
            errors.email = 'Invalid email address';
        }
        if (register.maritalStatus.trim() === '') {
            errors.maritalStatus = 'Marital Status is required';
        }
        if (register.fathersName.trim() === '') {
            errors.fathersName = 'Fathrer Name is required';
        }
        // Validate account
        if (register.bankaccountno.trim() === '') {
            errors.bankaccountno = 'Account is required';
        }
        if (register.relationShip.trim() === '') {
            errors.relationShip = 'Relation is required';
        }

        // Validate under_userid
        // if (register.under_userid.trim() === '') {
        //   errors.under_userid = 'Under User ID is required';
        // }
        if (register.bankName.trim() === '') {
            errors.bankName = 'Bank Name is required';
        }

        if (register.nomineeName === '') {
            errors.nomineeName = 'Nominee is required';
        }

        // Validate name
        if (register.name.trim() === '') {
            errors.name = 'Name is required';
        }

        // Validate dateOf_birth
        if (register.dateOf_birth.trim() === '') {
            errors.dateOf_birth = 'Date of birth is required';
        }

        // Validate maritalStatus
        if (register.maritalStatus.trim() === '') {
            errors.maritalStatus = 'Marital status is required';
        }

        // Validate gender
        if (register.gender.trim() === '') {
            errors.gender = 'Gender is required';
        }

        // Validate country
        if (register.country.trim() === '') {
            errors.country = 'Country is required';
        }

        // Validate state
        if (register.state.trim() === '') {
            errors.state = 'State is required';
        }

        // Validate district
        if (register.district.trim() === '') {
            errors.district = 'District is required';
        }

        // Validate city
        if (register.city.trim() === '') {
            errors.city = 'City is required';
        }

        // Validate pincode
        if (register.pincode.trim() === '') {
            errors.pincode = 'Pincode is required';
        }

        // Validate bankName
        if (register.bankName.trim() === '') {
            errors.bankName = 'Bank name is required';
        }
        if (register.fathersName.trim() === '') {
            errors.fathersName = 'Fathrer Name is required';
        }
        if (register.pancardNo.trim() === '') {
            errors.pancardNo = 'Pan Number is required';
        }
        // Validate account_HolderName
        if (register.account_HolderName.trim() === '') {
            errors.account_HolderName = 'Account holder name is required';
        }

        // Validate branch
        if (register.branch.trim() === '') {
            errors.branch = 'Branch is required';
        }

        // Validate ifscCode
        if (register.ifscCode.trim() === '') {
            errors.ifscCode = 'IFSC code is required';
        }
        if (register.aadharcardNo.trim() === '') {
            errors.aadharcardNo = 'Aadhar is required';
        }
        setValidationErrors(errors);

        return Object.keys(errors).length === 0;
    }

    function isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function isValidMobile(mobile) {
        // Add your mobile validation logic here
        return true;
    }

    function isValidSponserId(sponserId) {
        // Add your sponserId validation logic here
        return true;
    }

    function isValidDOJ(date_of_join) {
        // Add your date_of_joining validation logic here
        return true;
    }
    function isValidFaterName(fathersName) {
        return true
    }
    function isValidAadharCardNo(aadharcardNo) {
        return true
    }
    function isValidPanCardNo(pancardNo) {
        return true
    }
    function isValidDistrict(district) {
        return true
    }
    function isValidNomineeName(nomineeName) {
        return true
    }
    function isValidAccountHolderName(acc_holder_name) {
        return true
    }
    const handleSubmit = async (event) => {
        console.log(register, "reg")
        event.preventDefault();
        const isValid = validateForm();

        if (isValid) {
            const response = dispatch(joinUsers(register))
            history.push('/tree'); // Redirect to the dashboard page

        } else {
            alert("please fill all the field")
        }
    }




    return (

        <div>
            <head>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <title>Registration Form in HTML CSS</title>
                <link rel="stylesheet" href="style.css" />
            </head>
            <body className='bodys'>
                <section class="containerForm">
                    <h1 style={{ textAlign: 'center' }}>Registration Form</h1>
                    <form action="#" class="form">
                        <div className='section'>
                            <h2>Sponser details</h2>

                            <div className='column'>
                                <div class="input-box">
                                    <label>Sponser Id</label>
                                    <input type="text" placeholder="Enter full name" name="sponserid" id="sponserid" value={register.sponserId} readOnly
                                    />
                                    {validationErrors.sponserId && (
                                        <div className="text-danger">{validationErrors.sponserId}</div>
                                    )}
                                </div>

                                <div class="input-box">
                                    <label>Under User Id</label>
                                    <input type="text" placeholder="Enter email address"
                                        value={underuser} id="under_user_id" name="under_user_id"
                                        onChange={(e) => setRegister({ ...register, under_userid: underuser })}
                                    />
                                </div>
                            </div>


                            <fieldset className="column">
                                <div className="row">
                                    <legend className="col-form-label col-sm-2 pt-0"><b>Side</b></legend>
                                    <div className="col-sm-10">
                                        <div className="form-check">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="side"
                                                id="gridRadios1"
                                                value={clickside}
                                                checked={clickside}
                                                onChange={(e) => setRegister({ ...register, side: clickside })}
                                            />
                                            <label className="form-check-label" htmlFor="gridRadios1">
                                                {clickside}
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </fieldset>
                        </div>
                        <div className='section'>
                            <h2>Personal Details</h2>
                            <div class="input-box">
                                <label>Full Name</label>
                                <input type="text" placeholder="Enter full name"
                                    name="name"
                                    id="name"
                                    onChange={(e) => setRegister({ ...register, name: e.target.value })} required />
                                {validationErrors.name && (
                                    <div className="text-danger">{validationErrors.name}</div>
                                )}

                            </div>



                            <div className='column'>
                                <div class="input-box">
                                    <label>Father's Name</label>
                                    <input type="text" placeholder="Enter Father's name"
                                        name="fathername"
                                        id="fathername"
                                        onChange={(e) => setRegister({ ...register, fathersName: e.target.value })} required />
                                    {validationErrors.fathersName && (
                                        <div className="text-danger">{validationErrors.fathersName}</div>
                                    )}
                                </div>
                                <div class="input-box">
                                    <label>Birth Date</label>
                                    <input type="date" placeholder="Enter birth date"
                                        onChange={(e) => setRegister({ ...register, dateOf_birth: e.target.value })} required />
                                    {validationErrors.dateOf_birth && (
                                        <div className="text-danger">{validationErrors.dateOf_birth}</div>
                                    )}
                                </div>
                            </div>

                            <div className='column'>
                                <div class="input-box">

                                    <label>Nominee Name</label>
                                    <input type="text" placeholder="Enter nominee name"
                                        name="nominee"
                                        id="nominee"
                                        value={register.nomineeName}
                                        onChange={(e) => setRegister({ ...register, nomineeName: e.target.value })} required />
                                    {validationErrors.nomineeName && (
                                        <div className="text-danger">{validationErrors.nomineeName}</div>
                                    )}
                                </div>


                                <div class="input-box">
                                    <label>Relation Ship</label>
                                    <input type="text" placeholder="RelationShip"
                                        name="relationship"
                                        id="relationship"
                                        onChange={(e) => setRegister({ ...register, relationShip: e.target.value })} required />
                                    {validationErrors.relationShip && (
                                        <div className="text-danger">{validationErrors.relationShip}</div>
                                    )}
                                </div>
                            </div>





                            <div class="column">
                                <div class="input-box">
                                    <label>Phone Number</label>
                                    <input type="number" placeholder="Enter phone number"
                                        name="mobile"
                                        id="mobile"
                                        value={register.mobile}
                                        onChange={(e) => setRegister({ ...register, mobile: e.target.value })} required />
                                    {validationErrors.mobile && (
                                        <div className="text-danger">{validationErrors.mobile}</div>
                                    )}
                                </div>
                                <div class="input-box">
                                    <label>Email Address</label>
                                    <input type="text" placeholder="Enter email address"
                                        name="email"
                                        id="email"
                                        onChange={(e) => setRegister({ ...register, email: e.target.value })} required />
                                    {validationErrors.email && (
                                        <div className="text-danger">{validationErrors.email}</div>
                                    )}
                                </div>

                            </div>
                            <div class="gender-box">
                                <h3>Gender</h3>
                                <div class="gender-option">
                                    <div class="gender">
                                        <input type="radio" id="check-male" name="gender" value='male'
                                            onChange={(e) => setRegister({ ...register, gender: e.target.value })} />
                                        <label for="check-male">male</label>
                                    </div>
                                    <div class="gender">
                                        <input type="radio" id="check-female" name="gender" value='female'
                                            onChange={(e) => setRegister({ ...register, gender: e.target.value })} />

                                        <label for="check-female">Female</label>
                                    </div>
                                    <div class="gender">
                                        <input type="radio" id="check-other" name="gender" value='others'
                                            onChange={(e) => setRegister({ ...register, gender: e.target.value })} />

                                        <label for="check-other">Others</label>
                                    </div>
                                    {validationErrors.gender && (
                                        <div className="text-danger">{validationErrors.gender}</div>
                                    )}
                                </div>
                            </div>
                            <div class="gender-box">
                                <h3>Marital Status</h3>
                                <div class="gender-option">
                                    <div class="gender">
                                        <input type="radio" id="check-married" name="maritalStatus" value='married'
                                            onChange={(e) => setRegister({ ...register, maritalStatus: e.target.value })}
                                        />

                                        <label for="check-male">Married</label>
                                    </div>
                                    <div class="gender">
                                        <input type="radio" id="check-unmarried" name="maritalStatus" value='unmarried'
                                            onChange={(e) => setRegister({ ...register, maritalStatus: e.target.value })} />


                                        <label for="check-female">Unmarried</label>
                                        {validationErrors.maritalStatus && (
                                            <div className="text-danger">{validationErrors.maritalStatus}</div>
                                        )}
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='section'>
                            <h2>Proof Details</h2>
                            <div className='column'>
                                <div class="input-box">
                                    <label>AadharCard Number</label>
                                    <input type="text" placeholder="Enter Aadharcard Number" required
                                        onChange={(e) => setRegister({ ...register, aadharcardNo: e.target.value })} />
                                    {validationErrors.aadharcardNo && (
                                        <div className="text-danger">{validationErrors.aadharcardNo}</div>
                                    )}
                                </div>
                                <div class="input-box">

                                    <label>PanCard Number</label>
                                    <input type="text" placeholder="Enter PanCard Number" required
                                        onChange={(e) => setRegister({ ...register, pancardNo: e.target.value })} />
                                    {validationErrors.pancardNo && (
                                        <div className="text-danger">{validationErrors.pancardNo}</div>
                                    )}
                                </div>



                            </div>

                        </div>

                        <div className='section'>
                            <h2>Address Details</h2>
                            {/* <h2 className="centered-heading">Address Details</h2> */}

                            <div class="input-box address">
                                <label>Address</label>
                                <input type="text" placeholder="Enter street address"
                                    onChange={(e) => setRegister({ ...register, address: e.target.value })} required />
                                {validationErrors.address && (
                                    <div className="text-danger">{validationErrors.address}</div>
                                )}
                                <div class="column">
                                    <div class="select-box">
                                        <select
                                            onChange={(e) => setRegister({ ...register, country: e.target.value })} >

                                            <option hidden>Country</option>
                                            <option>America</option>
                                            <option>Japan</option>
                                            <option>India</option>
                                            <option>Nepal</option>

                                        </select >
                                        <div> {validationErrors.country && (
                                            <div className="text-danger">{validationErrors.country}</div>
                                        )}</div><br />
                                    </div>

                                    <input type="text" placeholder="Enter your state" required
                                        onChange={(e) => setRegister({ ...register, state: e.target.value })} />
                                    <div>{validationErrors.state && (
                                        <div className="text-danger">{validationErrors.state}</div>
                                    )}</div>


                                    <input type="text" placeholder="Enter your city"
                                        onChange={(e) => setRegister({ ...register, city: e.target.value })}
                                        required />
                                    {validationErrors.city && (
                                        <div className="text-danger">{validationErrors.city}</div>
                                    )}
                                </div>
                                <div class="column">
                                    <input type="text" placeholder="Enter your district" required
                                        onChange={(e) => setRegister({ ...register, district: e.target.value })}
                                    />
                                    {validationErrors.district && (
                                        <div className="text-danger">{validationErrors.district}</div>
                                    )}
                                    <input type="number" placeholder="Enter postal code" required
                                        onChange={(e) => setRegister({ ...register, pincode: e.target.value })}
                                    />
                                    {validationErrors.pincode && (
                                        <div className="text-danger">{validationErrors.pincode}</div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='section'>
                            <h2>Bank Details</h2>
                            <div className='column'>
                                <div class="input-box">
                                    <label>Bank Account No</label>
                                    <input type="text" placeholder="Bank Account Number"
                                        onChange={(e) => setRegister({ ...register, bankaccountno: e.target.value })}

                                        required />
                                    {validationErrors.bankaccountno && (
                                        <div className="text-danger">{validationErrors.bankaccountno}</div>
                                    )}
                                </div>

                                <div class="input-box">
                                    <label>Bank Name</label>
                                    <input type="text" placeholder="Bank  Name" required
                                        onChange={(e) => setRegister({ ...register, bankName: e.target.value })}
                                    />
                                    {validationErrors.bankName && (
                                        <div className="text-danger">{validationErrors.bankName}</div>
                                    )}
                                </div>
                            </div>
                            <div className='column'>
                                <div class="input-box">
                                    <label>Account Holder Name</label>
                                    <input type="text" placeholder=" Account Holder Name" required
                                        onChange={(e) => setRegister({ ...register, account_HolderName: e.target.value })}
                                    />
                                    {validationErrors.account_HolderName && (
                                        <div className="text-danger">{validationErrors.account_HolderName}</div>
                                    )}
                                </div>

                                <div class="input-box">
                                    <label>Branch</label>
                                    <input type="text" placeholder="Enter Branch" required
                                        onChange={(e) => setRegister({ ...register, branch: e.target.value })}
                                    />
                                    {validationErrors.branch && (
                                        <div className="text-danger">{validationErrors.branch}</div>
                                    )}
                                </div>
                                <div class="input-box">
                                    <label>IFSC</label>
                                    <input type="text" placeholder="Enter IFSC Code" required
                                        onChange={(e) => setRegister({ ...register, ifscCode: e.target.value })}
                                    />
                                    {validationErrors.ifscCode && (
                                        <div className="text-danger">{validationErrors.ifscCode}</div>
                                    )}
                                </div>
                            </div>
                        </div>


                        <button onClick={handleSubmit}>Submit</button>
                    </form>
                </section>
            </body>
        </div>
    )
}

export default Join
