

import classnames from "classnames";
// javascipt plugin for creating charts
import Chart from "chart.js";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getItem } from 'utils/localStorageController';
import { useSelector } from 'react-redux';
import { API_URL } from 'config';
// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import CardsHeader from "components/Headers/CardsHeader.js";

import {chartOptions,parseOptions} from "variables/charts.js";
import AdminFooter from "components/Footers/AdminFooter";

function Dashboard() {
  const [data, setData] = useState('');
  const a_token = getItem('a_token');
  const user = useSelector((state) => state.user);
  const userid = user.signIn.userid;
  const [activeNav, setActiveNav] = React.useState(1);
  const [chartExample1Data, setChartExample1Data] = React.useState("data1");
  const toggleNavs = (e, index) => {
    e.preventDefault();
    setActiveNav(index);
    setChartExample1Data(chartExample1Data === "data1" ? "data2" : "data1");
  };
  if (window.Chart) {
    parseOptions(Chart, chartOptions());
  }
  const viewIncome = async () => {
    try {
      const response = await axios.get(API_URL + 'pages/user_details.php', {
        params: {
          userid: userid,
        },
        headers: {
          Authorization: `Bearer ${a_token}`,
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };
  useEffect(() => {
    viewIncome();
  }, []);

  return (
    <>
      <CardsHeader name="Default" parentName="Dashboards" />
      <Container className="mt--6" fluid>
        <Row>
          <Col xl="8">
            <Card className="bg-default">
            </Card>
          </Col>

        </Row>

<Row>

        <Col xl="11" className="mx-auto">
          <Card>
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <div className="col">
                  <h3 className="mb-0">User Details</h3>
                </div>

              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush " responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">S.No</th>
                  <th scope="col">About</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(data).map(([key, value]) => (
                  <React.Fragment key={key}>
                    {key === "name" && (
                      <tr>
                        <th scope="row">1</th>
                        <td>Name</td>
                        <td>{value}</td>
                      </tr>
                    )}
                    {key === "userid" && (
                      <tr>
                        <th scope="row">2</th>
                        <td>User ID</td>
                        <td>{value}</td>
                      </tr>
                    )}
                    {key === "mobile" && (
                      <tr>
                        <th scope="row">3</th>
                        <td>Mobile</td>
                        <td>{value}</td>
                      </tr>

                    )}
                    {key === "dateofjoining" && (
                      <tr>
                        <th scope="row">4</th>
                        <td>Date Of Join</td>
                        <td>{value}</td>
                      </tr>

                    )}
                  </React.Fragment>
                ))}



              </tbody>
            </Table>
          </Card>
        </Col>
        </Row>
      </Container>
      <AdminFooter/>
    </>
  );
}

export default Dashboard;
