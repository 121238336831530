
// import React, { useState } from 'react'
// // import Logo from './Razorpay.png'
// import Logo from '././RazorPay/razorpay.webp'

// import AppHeader from './AppHeader'
// import { useSelector } from 'react-redux'
// import { DataService } from 'config.axios'
// import Autopay from './RazorPay'
// import Razorpayment from './RazorPay/Razorpay'
// import axios from 'axios'
// import { API_URL } from 'config'
// import { getItem } from 'utils/localStorageController'
// import Apps from './RazorPay/Pay'
// import Pay from './RazorPay/Pay'
// import { backgroundImage } from 'styled-system'

// const Razorpay = () => {
 

//   return (
//     <>
//       <AppHeader />
//        <div className=' text-center' style={{
//         backgroundImage:{Logo},
        
//         background-size: cover;
//         background-position: center;
//         background-attachment: fixed;
//         background-repeat: no-repeat;
//         width: 100vw;
//         height: 100vh;

//         }}> 

       
//           {/* <img src={Logo} alt='pay 2500' /> */}

//           <Pay/> 
       
//       </div>
//     </>

//   )
// }

// export default Razorpay
import React, { useState } from 'react';
import Logo from '././RazorPay/razorpay.webp';
import AppHeader from './AppHeader';
import Pay from './RazorPay/Pay';

const Razorpay = () => {
  return (
    <>
      <AppHeader />
      <div
        className='text-center'
        style={{
          backgroundImage: `url(${Logo})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed',
          backgroundRepeat: 'no-repeat',
          width: '100vw',
          height: '100vh',
        }}
      >
        <Pay/>
      </div>
    </>
  );
};

export default Razorpay;

