import axios from 'axios';
import { API_URL } from 'config';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getItem } from 'utils/localStorageController';
import './Withdrawalstatus.css';
import { useHistory } from 'react-router-dom'; // Import useHistory hook
const Withdrawalstatus = () => {
  const history = useHistory(); // Initialize useHistory
  const UserDashboardState = useSelector((state) => state.userDashboard)
  const user = useSelector((state) => state.user);
  const userid = user.signIn.userid;
  const [amount, setAmount] = useState('')
  const a_token = getItem('a_token');
  const [income, setIncome] = useState('')
  const requestHandling = async () => {
    try {
      const formData = new FormData();
      formData.append('userid', userid);
      formData.append('amount', amount);
  
      const response = await axios.post(
        API_URL + 'pages/withdraw_request.php',
        formData,
        {
          headers: {
            Authorization: `Bearer ${a_token}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    
  
      const { success, message } = response.data;
      if (success) {
        console.log('Withdrawal request inserted:', message);
        alert(message)
        await ShowIncome();
        history.push('/dashboard'); // Redirect to the dashboard page
      } else {
        alert(message)
        console.error('Withdrawal request failed:', message);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const ShowIncome =  async () => {
    try {
      const response = await axios.get(API_URL +
        "pages/dashboard.php",
        {
          params: {
            userid: userid,
          },
          headers: {
            Authorization: `Bearer ${a_token}`,
          },
        }
      );
      setIncome(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    ShowIncome();
  }, []);

  return (
    <div className="container mt-5">
                  <h1 className="font-weight-bold text-center">WITHDRAWAL REQUEST</h1>

      <div className="row">
        <div className="col-sm-6 widthdraw">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Current Balance</h3>
              <a href="#" className="btn btn-animate current-income">{income.current_income}</a>
            </div>
          </div>
        </div>

        <div className="col-sm-6 widthdraw">
          <div className="card">
            <div className="card-body">
              <h3 className="card-title">Total Balance</h3>
              <a href="#" className="btn btn-animate total-income">{income.total_income}</a>
            </div>
          </div>
        </div>
      </div>

      <div className="col-sm-12 widthdraw">
        <div className="card">
          <div className="card-body">
            <div className="form-row">
              <div className="col-md-4">
                <label htmlFor="userid">User ID:</label>
              </div>
              <div className="col-md-8 widthdraw">
                <input
                  type="text"
                  className="form-control"
                  id="userid"
                  name="userid"
                  value={userid}
                  readOnly
                  required
                />
              </div>
            </div>

            <br />

            <div className="form-row">
              <div className="col-md-4">
                <label htmlFor="withdraw-amount">Withdraw Amount:</label>
              </div>
              <div className="col-md-8 widthdraw">
                <input
                  type="number"
                  className="form-control"
                  id="withdraw-amount"
                  name="withdraw-amount"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  required
                  
                />
              </div>
            </div>

            <br />

            <div className="text-center">
              <button type="submit" className="btn btn-primary" onClick={requestHandling}>
                Request
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Withdrawalstatus;
