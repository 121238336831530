
/*!

=========================================================
* Argon Dashboard PRO React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
import { useSelector, useDispatch } from 'react-redux'
import { useState } from 'react'
import ShowSpinner from 'components/Spinner/spinner'
import AuthNavbar from "components/Headers/AuthHeader";
import './Login.css'
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import { loginInputChange, loginUser } from 'reduxStore/slices/user/UserSlice'


function Login(props) {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);
  const { signIn } = props
  const { userid, password } = signIn
  const session = useSelector((state) => state.user)
  console.log(session, "session")
  const [visible, setVisible] = useState(true)
  const onDismiss = () => setVisible(false)

  const dispatch = useDispatch()

  const handleInputChange = (e) => {
    e.preventDefault()
    dispatch(loginInputChange({ name: e.target.name, value: e.target.value }))
  }

  const login = (e) => {
    e.preventDefault()
    dispatch(loginUser(userid, password))
  }

  if (session.signIn.load || session.signIn.pageLoad) {
    return <ShowSpinner />
  }
  return (
    <div className="body">
      {/* <div className="logs"> */}
     
    
      <div className="boxlogin">
     
        <div className="containerlogin">
        <div className="heading-container text-center ">
        <h2 className="logo-text">Welcome to</h2>
        <h4 className="sub-heading-text">Amazing Product</h4>
      </div>
          <div className="top">
            <span className="spanlogin">Have an account?</span>
            <header className="header">Login</header>
          </div>
          <div className="input-fieldlogin">
            <input type="text" className="inputlog" placeholder="User Id"
               value={userid} name='userid'
                        onChange={(e) => {
                          handleInputChange(e)
                        }}/>
            <i className='bx bx-user icons'></i>
          </div>
          <div className="input-fieldlogin">
            <input type="password" className="inputlog" placeholder="Password"  
            value={password} name="password"
                        onChange={(e) => {
                          handleInputChange(e)
                        }}/>
            <i className='bx bx-lock-alt icons'></i>
          </div>
          <div className="input-fieldlogin login">
            <input type="submit" className="submitlogin" value="Login" id="" onClick={login}/>
          </div>
         
        </div>
      </div>
      </div>
    // </div>
  );
}

export default Login;
