import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "config";
const initialState = {
    data: []
}

const PaymentReceivedSlice = createSlice({
    name: "PaymentRecivedSlice",
    initialState, 
    reducers: {
        setData: (state, action) => {
            state.data = action.payload
        }
    }
})

export const viewIncome = (userid,a_token) => async (dispatch) => {
    try {
      const response = await axios.get(
        API_URL+'pages/payment_receive_history.php',
        {
          params: {
            userid: userid,
          },
          headers: {
            Authorization: `Bearer ${a_token}`,
          },
        }
      );
      console.log(response,"iiiiiiiiiiiiiiiiiiiiiii")
      dispatch(setData(response.data.data));
    } catch (error) {
      console.error('Error retrieving pin request:', error);
    }
  };

export const {setData} = PaymentReceivedSlice.actions

export default PaymentReceivedSlice.reducer