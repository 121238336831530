import React, { useState } from 'react'
import { Grid } from "@material-ui/core";
import AppHeader from 'layouts/AppHeader';
import { AppBody } from 'style'
import SidebarAdmin from 'components/Sidebar/Admin/SidebarAdmin';
import Sidebar from 'components/Sidebar/Admin/Sidebar';
import dashboardStyle from "./dashboardstyle";
export default function Admin(props) {
  const classes = dashboardStyle();
  const [mobileOpen, setMobileOpen] = useState(false);
  return (
<div>

{/* <Grid container className={classes.root}> */}
<AppHeader mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
  <SidebarAdmin mobileOpen={mobileOpen} setMobileOpen={setMobileOpen}/>
 

      {/* <DashboardAppbar mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <MainDrawer mobileOpen={mobileOpen} setMobileOpen={setMobileOpen} />
      <MainContent /> */}
    {/* </Grid>  */}

</div>

  )
}


// import React from "react";
// // react library for routing
// import { useLocation, Route, Redirect } from "react-router-dom";
// // core components
// import AdminNavbar from "components/Navbars/AdminNavbar.js";
// import AdminFooter from "components/Footers/AdminFooter.js";
// import Sidebar from "components/Sidebar/Sidebar.js";
// import AppHeader from 'layouts/AppHeader';
// import { AppBody } from 'style'
// import SidebarAdmin from 'components/Sidebar/Admin/SidebarAdmin';
// import routes from "routes.js";

// function Admin() {
//   const [sidenavOpen, setSidenavOpen] = React.useState(true);
//   const location = useLocation();
//   const mainContentRef = React.useRef(null);
//   React.useEffect(() => {
//     document.documentElement.scrollTop = 0;
//     document.scrollingElement.scrollTop = 0;
//     mainContentRef.current.scrollTop = 0;
//   }, [location]);
//   const getRoutes = (routes) => {
//     return routes.map((prop, key) => {
//       if (prop.collapse) {
//         return getRoutes(prop.views);
//       }
//       if (prop.layout === "/admin") {
//         return (
//           <Route path={prop.path} element={prop.component} key={key} exact />
//         );
//       } else {
//         return null;
//       }
//     });
//   };
//   const getBrandText = (path) => {
//     for (let i = 0; i < routes.length; i++) {
//       if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
//         return routes[i].name;
//       }
//     }
//     return "Brand";
//   };
//   // toggles collapse between mini sidenav and normal
//   const toggleSidenav = (e) => {
//     if (document.body.classList.contains("g-sidenav-pinned")) {
//       document.body.classList.remove("g-sidenav-pinned");
//       document.body.classList.add("g-sidenav-hidden");
//     } else {
//       document.body.classList.add("g-sidenav-pinned");
//       document.body.classList.remove("g-sidenav-hidden");
//     }
//     setSidenavOpen(!sidenavOpen);
//   };
//   const getNavbarTheme = () => {
//     return location.pathname.indexOf("admin/alternative-dashboard") === -1
//       ? "dark"
//       : "light";
//   };

//   return (
//     <>
//     {/* <AppHeader />

// //   <SidebarAdmin/>
// //   <AppBody /> */}
//       <SidebarAdmin
//         routes={routes}
//         toggleSidenav={toggleSidenav}
//         sidenavOpen={sidenavOpen}
//         logo={{
//           innerLink: "/",
//           imgSrc: require("assets/img/brand/argon-react.png"),
//           imgAlt: "...",
//         }}
//       />
//       <div ref={mainContentRef}>
//         {/* <AppBody
//           theme={getNavbarTheme()}
//           toggleSidenav={toggleSidenav}
//           sidenavOpen={sidenavOpen}
//           brandText={getBrandText(location.pathname)}
//         /> */}
      
         
     
//         {/* <Admi /> */}
//       </div>
     
//     </>
//   );
// }

// export default Admin;

