import { createSlice } from "@reduxjs/toolkit";
import { DataService } from "config.axios";

const initialState = {
    payment: []
}
const UserInitialPaymentSlice = createSlice({
    name: "UserInitialPaymentSlice",
    initialState,
    reducers: {
        setPayment: (state, action) => {
            state.payment = action.payload
        },

    }
})
export const fetchData = () => async (dispatch) => {
    try {
        const response = await DataService.post(
            'admin/initial_payment_receive.php',

        );
        dispatch(setPayment(response.data));
        console.log(response.data,"payment")
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};
export const unlockHandling = (id, userid) => async (dispatch) => {
    try {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('userid', userid)

        const sendData = await DataService.post('admin/unlockuser.php', formData);
        if(sendData.data.success) {
            dispatch(fetchData())
            alert('Unlocked Successfully')
            setPayment((prevData) => prevData.filter((item) => item.id !== id));
        }
    } catch (error) {
        console.error('Error sending pin:', error);
    }
};


export const { setPayment } = UserInitialPaymentSlice.actions

export default UserInitialPaymentSlice.reducer